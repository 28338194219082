<template>
    <path id="Vector (Stroke)_28" fill-rule="evenodd" clip-rule="evenodd"
        d="M736.519 170H828.031L862.547 239.033L828.031 308.065H736.513L702 238.891L736.519 170ZM743.232 180.871L714.154 238.902L743.238 297.194H821.312L850.393 239.033L821.312 180.871H743.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_29" fill-rule="evenodd" clip-rule="evenodd"
        d="M736.519 327H828.031L862.547 396.033L828.031 465.065H736.513L702 395.891L736.519 327ZM743.232 337.871L714.154 395.902L743.238 454.194H821.312L850.393 396.033L821.312 337.871H743.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_30" fill-rule="evenodd" clip-rule="evenodd"
        d="M872.519 250H964.031L998.547 319.033L964.031 388.065H872.513L838 318.891L872.519 250ZM879.232 260.871L850.154 318.902L879.238 377.194H957.312L986.393 319.033L957.312 260.871H879.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_31" fill-rule="evenodd" clip-rule="evenodd"
        d="M872.519 398H964.031L998.547 467.033L964.031 536.065H872.513L838 466.891L872.519 398ZM879.232 408.871L850.154 466.902L879.238 525.194H957.312L986.393 467.033L957.312 408.871H879.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_32" fill-rule="evenodd" clip-rule="evenodd"
        d="M1010.52 11H1102.03L1136.55 80.0326L1102.03 149.065H1010.51L976 79.8915L1010.52 11ZM1017.23 21.8713L988.154 79.9018L1017.24 138.194H1095.31L1124.39 80.0325L1095.31 21.8713H1017.23Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_33" fill-rule="evenodd" clip-rule="evenodd"
        d="M1010.52 160H1102.03L1136.55 229.033L1102.03 298.065H1010.51L976 228.891L1010.52 160ZM1017.23 170.871L988.154 228.902L1017.24 287.194H1095.31L1124.39 229.033L1095.31 170.871H1017.23Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_34" fill-rule="evenodd" clip-rule="evenodd"
        d="M1010.52 319H1102.03L1136.55 388.033L1102.03 457.065H1010.51L976 387.891L1010.52 319ZM1017.23 329.871L988.154 387.902L1017.24 446.194H1095.31L1124.39 388.033L1095.31 329.871H1017.23Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_35" fill-rule="evenodd" clip-rule="evenodd"
        d="M1148.52 395H1240.03L1274.55 464.033L1240.03 533.065H1148.51L1114 463.891L1148.52 395ZM1155.23 405.871L1126.15 463.902L1155.24 522.194H1233.31L1262.39 464.033L1233.31 405.871H1155.23Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_36" fill-rule="evenodd" clip-rule="evenodd"
        d="M47.5194 91H139.031L173.547 160.033L139.031 229.065H47.513L13 159.891L47.5194 91ZM54.2318 101.871L25.1544 159.902L54.2383 218.194H132.312L161.393 160.033L132.312 101.871H54.2318Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_37" fill-rule="evenodd" clip-rule="evenodd"
        d="M47.5194 250H139.031L173.547 319.033L139.031 388.065H47.513L13 318.891L47.5194 250ZM54.2318 260.871L25.1544 318.902L54.2383 377.194H132.312L161.393 319.033L132.312 260.871H54.2318Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_38" fill-rule="evenodd" clip-rule="evenodd"
        d="M47.5194 407H139.031L173.547 476.033L139.031 545.065H47.513L13 475.891L47.5194 407ZM54.2318 417.871L25.1544 475.902L54.2383 534.194H132.312L161.393 476.033L132.312 417.871H54.2318Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_39" fill-rule="evenodd" clip-rule="evenodd"
        d="M183.519 11H275.031L309.547 80.0326L275.031 149.065H183.513L149 79.8915L183.519 11ZM190.232 21.8713L161.154 79.9018L190.238 138.194H268.312L297.393 80.0325L268.312 21.8713H190.232Z"
        fill="#E8D9AA" />
    <path id="Vector (Stroke)_40" fill-rule="evenodd" clip-rule="evenodd"
        d="M183.519 170H275.031L309.547 239.033L275.031 308.065H183.513L149 238.891L183.519 170ZM190.232 180.871L161.154 238.902L190.238 297.194H268.312L297.393 239.033L268.312 180.871H190.232Z"
        fill="#E8D9AA" />
</template>